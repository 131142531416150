import React from "react";

import service1 from "../../assets/service1.png";
import service2 from "../../assets/services2.png";
import service3 from "../../assets//service3.png";
const Careers = () => {
  const formLink = "https://forms.gle/DxsbVpU4bMSL4TpEA";

  return (
    <div class="flex flex-col justify-center items-center mt-8">
      <h5
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        class="font-bold text-gray-700 text-2xl sm:text-md text-center underline underline-offset-4 decoration-customprimary mb-10"
      >
        Careers At Softwareosity
      </h5>

      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        class="flex flex-col flex-wrap justify-center items-center mx-auto"
      >
        <div className="border-2 rounded-md border-customprimary mx-auto lg:mx-5 xl:mx-5 2xl:mx-5 w-[375px] sm:w-[375px] md:w-[600px] lg:w-fit xl:w-fit 2xl:w-fit">
          <div className="flex flex-col justify-left">
            <h5 className="font-bold text-xl text-left my-2 mt-5 mx-4 underline underline-offset-4 decoration-customprimary">
              Lead Generation Specialist
            </h5>

            <p className="text-md text-left my-2 mx-4">
              Softwareosity is a software development agency specializing in AI
              And Enterprise software.
            </p>

            <p className="text-md text-left my-2 mx-4">
              We are currently looking for a lead generation specialist with the
              experience to collect and evaluate potential sales opportunities
              and manage deals.
            </p>

            <p className="text-md text-left my-2 mx-4">
              You will be responsible for generating quality leads and managing
              deals.
            </p>

            <p className="text-md text-left my-2 mx-4">
              This opportunity will be predominantly focused on our new business
              expansion plans across North American Region.
            </p>

            <h5 className="font-bold text-lg text-left my-2 mx-4 underline underline-offset-4 decoration-customprimary">
              Responsibilities:
            </h5>
            <ul className="list-disc text-md text-left mx-4 list-inside	">
              <li>
                Own the entire sales cycle from outreach to closing deals with
                high-growth businesses across the world.
              </li>
              <li>
                Build your market - proactively grow and manage an account list
                of potential clients, and drive strategies to nurture
                opportunities with each account.
              </li>
              <li>
                Storytelling - Leading potential clients to an understanding of
                the options or solutions that are applicable to their situation,
                demonstrating how features and benefits of personalised software
                match their needs.
              </li>
              <li>
                Direct outgoing calling/email marketing to key clients and
                prospects to develop new business.
              </li>
              <li>Daily prospecting and assessment of new sales potential.</li>
              <li>
                Provide accurate and timely information to the development team.
              </li>
            </ul>

            <h5 className="font-bold text-lg text-left my-2 mx-4 underline underline-offset-4 decoration-customprimary">
              Requirements:
            </h5>
            <ul className="list-disc text-md text-left mx-4 list-inside	">
              <li>
                Sales experience (Internship or Full-Time), with a proven track
                record in achieving quotas.
              </li>
              <li>
                Track record of success as a salesperson, account manager, or
                consultant, preferably in the software industry.
              </li>
              <li>
                Demonstrated success in driving new business sales, and ability
                to develop and manage a sales execution plan at an account
                and/or territory level.
              </li>
              <li>
                Goal-Getter. Strive to achieve sales KPIs daily, weekly,
                monthly, etc. You are willing to go the extra mile with a strong
                work ethic; self-directed and resourceful.
              </li>
              <li>Proficient In Arabic And English.</li>
              <li>Must have a decent laptop and an internet connection.</li>
            </ul>

            <h5 className="font-bold text-lg text-left my-2 mx-4 underline underline-offset-4 decoration-customprimary">
              Compensation:
            </h5>
            <ul className="list-disc text-md text-left mx-4 list-inside">
              <li className="font-bold">
                NO BASE SALARY. COMMISSION BASIS ONLY.
              </li>
              <li>
                Get a decent percentage (Minimum 5%) of every deal that you
                close.
              </li>
            </ul>

            <h5 className="font-bold text-lg text-left my-2 mx-4 underline underline-offset-4 decoration-customprimary">
              Benefits:
            </h5>
            <ul className="list-disc text-md text-left mx-4 list-inside">
              <li>
                You get to work in a liberal environment with some of the best
                engineers and entrepreneurs.
              </li>
              <li>Work as you please, as long as the targets are met.</li>
              <li>Remote work culture.</li>
            </ul>

            <h5 className="font-bold text-lg text-left my-2 mx-4 underline underline-offset-4 decoration-customprimary">
              Preference Points:
            </h5>
            <ul className="list-disc text-md text-left mx-4 list-inside">
              <li>
                Understanding of common ML-AI norms and passionate about
                software.
              </li>
              <li>
                Experience working in blockchain and enterprise software space.
              </li>
              <li>
                Founded or have worked in a growth role at a startup previously.
              </li>
              <li>Proficient with American English.</li>
            </ul>
          </div>

          <a href={formLink} target="_blank" rel="noreferrer">
            <button
              type="button"
              class="text-customsecondary my-5 bg-customprimary hover:bg-customsecondary hover:text-customprimary hover:ring-2 hover:ring-customprimary focus:ring-4 focus:outline-none focus:ring-customblack font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Apply Here
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Careers;
