import React from "react";

import service1 from "../../assets/service1.png";
import service2 from "../../assets/services2.png";
import service3 from "../../assets//service3.png";
const Services = () => {
  return (
    <div class="flex flex-col justify-center items-center">
      <div id="Services"> </div>

      <br />
      <br />
      <br />

      <h5
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        class="font-bold text-gray-700 text-2xl text-center underline underline-offset-4 decoration-customprimary mb-5"
      >
        Our Services
      </h5>

      <div class="flex flex-row flex-wrap justify-center items-center mx-auto">
        <div
          data-aos-anchor-placement="top-center"
          data-aos="fade-up"
          data-aos-delay="150"
          class="max-w-sm rounded-lg overflow-hidden shadow-lg mx-5 my-5"
        >
          <img class="w-full" src={service1} alt="Blockchain Tech" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 underline underline-offset-4 decoration-customprimary">
              AI Apps &amp; Solutions
            </div>
            <p class="text-gray-700 text-base py-4">
              We Build AI Apps & Services with content generation &amp; business
              applications
            </p>
          </div>
        </div>

        <div
          data-aos-anchor-placement="top-center"
          data-aos="fade-up"
          data-aos-delay="300"
          class="max-w-sm rounded-lg overflow-hidden shadow-lg mx-5 my-5"
        >
          <img class="w-full" src={service2} alt="Enterprise Apps" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 underline underline-offset-4 decoration-customprimary">
              CRM / ERP Solutions
            </div>
            <p class="text-gray-700 text-base py-4">
              Automate daily operations &amp; tasks with our AI-powered CRM/ERP
              systems
            </p>
          </div>
        </div>

        <div
          data-aos-anchor-placement="top-center"
          data-aos="fade-up"
          data-aos-delay="500"
          class="max-w-sm rounded-lg overflow-hidden shadow-lg mx-5 my-5"
        >
          <img class="w-full" src={service3} alt="Ideating" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 underline underline-offset-4 decoration-customprimary">
              Software Consulting
            </div>
            <p class="text-gray-700 text-base py-4">
              Got an idea that needs software? We're here to build &amp; execute
              it for you
            </p>
          </div>
        </div>
      </div>
      <div id="Clients"> </div>
      <br />
    </div>
  );
};

export default Services;
