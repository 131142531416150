import React from "react";
import illustrationPic from "../../assets/illustration.png";
import { Link } from "react-scroll";

const Home = () => {
  const meetingLink = "https://calendly.com/softwareosity/free-consultation";
  //const formLink = "https://forms.gle/ph3uhfZXFasuPtwx8";
  const formLink = meetingLink;

  return (
    <div
      class="flex flex-row flex-wrap justify-center items-center mx-5 my-12"
      id="Home"
    >
      <div class="flex flex-col justify-center 3xl:ml-20 2xl:ml-20 mt-8">
        <h5
          data-aos="fade-up"
          class="font-bold text-gray-700 3xl:text-4xl 2xl:text-4xl xl:text-4xl text-3xl 3xl:text-left 2xl:text-left xl:text-center lg:text-center md:text-center sm:text-center text-center  underline underline-offset-4 decoration-customprimary mb-5"
        >
          Delivering Custom Software Solutions <br /> That Drive Your Business
          Forward
        </h5>

        <p
          data-aos="fade-up"
          data-aos-delay="200"
          class="3xl:text-left 2xl:text-left xl:text-center lg:text-center md:text-center sm:text-center text-center font-semibold mb-10"
        >
          We're your software development partner for success: solving complex
          problems and <br /> strategizing faster solutions. Think of us as your
          specialized internal team <br /> without the hassle, but with all the
          benefits.
        </p>
        <a
          href={formLink}
          target="_blank"
          rel="noreferrer"
          data-aos="fade-up"
          data-aos-delay="300"
          type="button"
          class="3xl:max-w-xl 2xl:max-w-xl xl:max-w-xl lg:max-w-xl md:max-w-xl sm:max-w-xl max-w-xl text-customsecondary bg-customprimary hover:bg-customsecondary hover:text-customprimary hover:ring-2 hover:ring-customprimary focus:ring-4 focus:outline-none focus:ring-customblack rounded-lg text-xl px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <button className="font-bold">Book a Free Consultation</button>
        </a>
      </div>

      <div
        data-aos="zoom-out"
        data-aos-delay="200"
        class="flex justify-center items-center 3xl:ml-20 2xl:mr-20 my-20"
      >
        <img
          src={illustrationPic}
          class="w-max"
          alt="Software Working Illustration"
        />
      </div>
    </div>
  );
};

export default Home;
