import React, { useEffect } from "react";
import { Route, Routes, Redirect, BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import Contact from "./components/Contact/Contact";
import Customers from "./components/Customers/Customers";
import Footer from "./components/Footer/Footer";
import Nav from "./components/Nav/Nav";
import Services from "./components/Services/Services";
import Home from "./containers/Home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import "./output.css";
import Careers from "./components/Careers/Careers";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-5ZRGMW4",
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    // AOS.refresh();
  }, []);

  return (
    <div class="App">
      <Nav />

      <div class="scroll-smooth">
        <br /> <br />
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <div>
                    <Home /> <Services />
                    <br />
                    <br />
                    <Customers />
                    <br /> <br /> <br /> <br />
                    <Contact />
                  </div>
                }
              />
              <Route
                path="*"
                element={
                  <div>
                    <Home />
                    <Services />
                    <br />
                    <br />
                    <Customers />
                    <br /> <br /> <br /> <br />
                    <Contact />
                  </div>
                }
              />

              <Route path="careers" element={<Careers />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </div>
  );
}

export default App;
