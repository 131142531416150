import React from "react";

import sdxo from "../../assets/clientlogos/sdxo.png";
import mshark from "../../assets/clientlogos/mshark.png";
import sswap from "../../assets/clientlogos/sswaplogo.png";
import gg from "../../assets/clientlogos/gg.png";
import sector44 from "../../assets/clientlogos/sector44.png";
import madmonkey from "../../assets/clientlogos/mad-monkey.png";
import unbubble from "../../assets/clientlogos/unbubble.png";
import "./CustomersNew.css";

const Customers = () => {
  // const formLink = "https://forms.gle/ph3uhfZXFasuPtwx8";
  const meetingLink = "https://calendly.com/softwareosity/free-consultation";

  return (
    <div class="flex flex-col justify-center items-center mb-10">
      <h5
        data-aos="fade-up"
        class="font-bold text-gray-700 text-2xl text-center underline underline-offset-4 decoration-customprimary mb-10"
      >
        Our Clients
      </h5>

      <div
        data-aos="fade-up"
        data-aos-delay="200"
        class="flex flex-row flex-wrap justify-between items-center"
        id="scrolling-text"
      >
        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3  flex justify-center mx-auto my-5">
          <img
            src={madmonkey}
            class="h-fit w-fit shrink-0"
            alt="mad-monkey.de"
          />
        </div>

        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex justify-center mx-auto my-5">
          <img
            src={unbubble}
            class="h-fit unbubble rounded-lg shrink-0"
            alt="UnBubble GmbH"
          />
        </div>

        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3  flex justify-center mx-auto my-5">
          <img src={sector44} class="h-fit w-fit shrink-0" alt="Sector44" />
        </div>

        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex justify-center mx-auto my-5">
          <img
            src={gg}
            class="h-fit w-fit rounded-lg shrink-0"
            alt="Greedy Gramps NFT"
          />
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="200"
        class="flex flex-row flex-wrap justify-between  items-center "
        id="scrolling-text"
      >
        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3  flex justify-center mx-auto my-5">
          <img src={sswap} class="h-fit w-fit shrink-0" alt="SocialSwap" />
        </div>

        <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3  flex justify-center mx-auto my-5">
          <img src={mshark} class="h-fit w-fit shrink-0" alt="MoneyShark NFT" />
        </div>
      </div>
      <div id="Contact"></div>
      <br />
      <br />
      <br />

      <h5
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="200"
        class="font-bold text-gray-700 text-2xl text-center underline underline-offset-4 decoration-customprimary mt-10 mb-5"
      >
        Get In Touch
      </h5>

      <p
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="200"
        class="my-5 font-bold text-gray-700 text-lg text-center mx-20"
      >
        Want to know how your business can make or save money by using our
        software? Lets talk 🤝
      </p>

      <a
        href={meetingLink}
        target="_blank"
        rel="noreferrer"
        data-aos="fade-up"
        data-aos-delay="300"
        type="button"
        class="3xl:max-w-xl 2xl:max-w-xl xl:max-w-xl lg:max-w-xl md:max-w-xl sm:max-w-xl max-w-xl text-customsecondary bg-customprimary hover:bg-customsecondary hover:text-customprimary hover:ring-2 hover:ring-customprimary focus:ring-4 focus:outline-none focus:ring-customblack rounded-lg text-xl px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <button className="font-bold">Book a Free Call</button>
      </a>
    </div>
  );
};

export default Customers;
