import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-scroll";

import navLogo from "../../assets/navlogo.png";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleNav = () => {
    setIsOpen(!isOpen);
  };

  // const meetingLink = "https://calendly.com/softwareosity/free-consultation";
  const formLink = "https://forms.gle/ph3uhfZXFasuPtwx8";
  // const formLink = meetingLink;
  return (
    <nav class="bg-customsecondary fixed left-0 top-0 right-0 navbar-top z-50 border-gray-200 px-2 sm:px-2 py-2.5 rounded dark:bg-gray-800">
      <div class="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" class="flex items-center">
          <img
            src={navLogo}
            class="mr-2 h-11 sm:h-11"
            alt="Softwareosity Logo"
          />
          <span class="self-center text-xl font-semibold whitespace-nowrap text-customprimary dark:text-customblack">
            softwareosity
          </span>
        </a>
        <div class="flex md:order-2">
          <a href={formLink} target="_blank" rel="noreferrer">
            <button
              type="button"
              class="hover:text-customsecondary hover:bg-customprimary bg-customsecondary text-customprimary ring-2 ring-customprimary focus:ring-4 focus:outline-none focus:ring-customblack font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Contact Us
            </button>
          </a>

          <button
            data-collapse-toggle="mobile-menu-4"
            type="button"
            onClick={handleNav}
            class="inline-flex items-center px-2 py-0 text-sm text-customsecondary bg-customprimary rounded-lg hover:ring-2 hover:ring-customprimary hover:text-customprimary hover:bg-customsecondary md:hidden focus:outline-none focus:ring-4 focus:ring-customblack dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-4"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              class="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          class="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-4"
        >
          <ul class="flex flex-col mt-4 -ml-14 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <a
                href="/"
                class="block py-2 pr-4 pl-3 text-gray-700 font-semibold hover:text-customprimary bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-customblack"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <Link activeClass="active" to="Services" spy={true} smooth={true}>
                <a
                  href="#Services"
                  class="block py-2 pr-4 pl-3 text-gray-700 font-semibold hover:text-customprimary border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-customblack dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-customblack md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Services
                </a>
              </Link>
            </li>

            {/* <li>
      <Link activeClass="active" to="About" spy={true} smooth={true}>
        <a href="#About" class="block py-2 pr-4 pl-3 text-gray-700 font-semibold hover:text-customprimary border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-customblack dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-customblack md:dark:hover:bg-transparent dark:border-gray-700">About</a>
      </Link>
      </li>  */}

            <li>
              <Link activeClass="active" to="Clients" spy={true} smooth={true}>
                <a
                  href="#Clients"
                  class="block py-2 pr-4 pl-3 text-gray-700 font-semibold hover:text-customprimary border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-customblack dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-customblack md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Clients
                </a>
              </Link>
            </li>

            <li>
              <Link activeClass="active" to="Contact" spy={true} smooth={true}>
                <a
                  href="#Contact"
                  class="block py-2 pr-4 pl-3 text-gray-700 font-semibold hover:text-customprimary border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-customblack dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-customblack md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Contact
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="xl:hidden" id="mobile-menu-4">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="/"
                className="hover:bg-customprimary text-gray-300 hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
              >
                Home
              </a>

              <Link activeClass="active" to="Services" spy={true} smooth={true}>
                <a
                  href="#Services"
                  className="text-gray-300 hover:bg-customprimary hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
                >
                  Services
                </a>
              </Link>

              {/* <Link activeClass="active" to="About" spy={true} smooth={true}>
                      
                            <a
                              href="#About"
                              className="text-gray-300 hover:bg-customprimary hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
                            >
                              About
                            </a>
                            </Link> */}

              <Link activeClass="active" to="Clients" spy={true} smooth={true}>
                <a
                  href="#Clients"
                  className="text-gray-300 hover:bg-customprimary hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
                >
                  Clients
                </a>
              </Link>

              <Link activeClass="active" to="Contact" spy={true} smooth={true}>
                <a
                  href="#Contact"
                  className="text-gray-300 hover:bg-customprimary hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
                >
                  Contact
                </a>
              </Link>
            </div>

            {/* <div class="flex flex-row-reverse">

                            <a href={twitterLink} target="_blank" rel="noreferrer" class="flex">
                            <img src={TwitterLogo} class="h-8 w-10 my-2 mx-4" alt="Twitter Logo" />
                            </a>


                            <a href={discordLink} target="_blank" rel="noreferrer" class="flex">
                            <img src={DiscordLogo} class="h-8 w-10 my-2 mx-4" alt="Discord Logo" />
                            </a>
                            
                      
                            </div> */}
          </div>
        )}
      </Transition>
    </nav>
  );
};

export default Nav;
