import React from "react";
import "./Contact.css";
import MailLogo from "../../assets/email.png";

const Contact = () => {
  return (
    <div class="flex flex-col justify-center items-center mt-0 mb-10">
      <h5
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        class="font-bold text-gray-700 text-2xl text-center underline underline-offset-4 decoration-customprimary my-10"
      >
        Contact Us
      </h5>

      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="200"
        class="max-w-xl rounded overflow-hidden shadow-lg mx-5"
      >
        <div class="flex flex-row flex-wrap">
          <div class="3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full flex justify-center">
            <img
              class="3xl:w-full 2xl:w-full xl:w-full lg:w-full md:w-1/2 sm:w-1/2 w-1/2"
              src={MailLogo}
              alt="Blockchain Tech"
            />
          </div>

          <div class="3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full my-5">
            <div class="flex flex-col justify-center h-full">
              <a
                href="mailto:contact@softwareosity.com"
                class="text-gray-700 font-bold my-2"
              >
                contact@softwareosity.com
              </a>

              <a
                href="mailto:arfan@softwareosity.com"
                class="text-gray-700 font-bold my-2"
              >
                arfan@softwareosity.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
